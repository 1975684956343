.productSection {
    display: flex;
    align-items: center;
    gap: 100px;
    padding: 60px 165px;
    max-width: 1600px;
    margin: auto;
}

.productContent {
    display: flex;
    flex-direction: column;
    max-width: 540px;
}

.productTitle {
    color: #333;
    font: 700 16px/28px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
    letter-spacing: 4.6px;
    text-transform: uppercase;
}

.productSubtitle {
    color: #191825;
    font: 700 32px/1.2 Roboto, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 16px;
}

.productDescription {
    color: rgba(25, 24, 37, 0.5);
    font: 400 18px/29px Inter, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 32px;
}

.ctaButton {
    align-self: flex-start;
    background-color: #f8d00d;
    border: none;
    border-radius: 100px;
    color: #000;
    cursor: pointer;
    font: 700 14px/1.2 Roboto, -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 64px;
    padding: 16px 32px;
}

.productImageContainer {
    position: relative;
}

.productImage {
    aspect-ratio: 1.14;
    border-radius: 12px;
    max-width: 658px;
    object-fit: contain;
    width: 100%;
    z-index: 10;
}

.associations {
    background: #FFCC00;
    display: flex;
    align-items: center;
}

.associationsImage img {
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px;
}

.associationsText {
    width: 700px;
    padding: 0px 50px;
    color: black;
}

.associationsText h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 42px;
}

.associationsText p {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 21px;
    color: black;
}


@media (max-width: 991px) {
    .productSection {
        flex-direction: column;
        padding: 40px 20px;
    }

    .productSubtitle {
        font-size: 32px;
    }

    .ctaButton {
        margin-top: 40px;
    }

    .decorativeCircle {
        display: none;
    }
}