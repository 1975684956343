.contactPage {
    font-family: 'Roboto', sans-serif;
}

.hero {
    background: url('https://images.unsplash.com/photo-1587560699334-cc4ff634909a?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') center/cover no-repeat;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.heroTitle {
    font-size: 48px;
    font-weight: 700;
    margin: 0;
}

.heroSubtitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.contentContainer {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.sectionTitle {
    margin-top: 5%;
    font-size: 24px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}

.sectionText {
    font-size: 16px;
    font-weight: 500;
    color: #555;
    margin-bottom: 20px;
}

.infoContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin-top: 5%;
}

@media screen and (max-width: 750px){
    .infoContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
        flex-direction: column;
    margin-top: 5%;
}
}

.contactBox {
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1 1 45%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.boxTitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
}

.boxContent {
    font-size: 16px;
    color: #555;
}

.location {
    font-weight: bold;
    margin-bottom: 10px;
}

.contactItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.icon {
    color: #333;
    margin-right: 8px;
}

.mapContainer {
    flex: 1 1 45%;
    max-width: 500px;
    min-height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.companyInfo{
    font-size: 10px;
    margin-top: 5%;
color: #6e7b8f;
}