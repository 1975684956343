.contactSection{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
   padding: 60px 165px;
  max-width: 1600px;
  margin: auto;
}

.contactForm {
  display: flex;
  flex-direction: column;
  max-width: 479px;
  margin: 60px auto;
  width: 100%;
}

.formRow {
  display: flex;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
}

.formLabel {
  color: #303a42;
  font: 400 22px Roboto, sans-serif;
  margin-bottom: 17px;
}

.formInput,
.formTextarea {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 10px;
  color: rgba(48, 58, 66, 0.5);
  font: 500 19px Roboto, sans-serif;
  padding: 22px 31px;
  width: 100%;
}

.formTextarea {
  height: 150px;
  resize: vertical;
}

.submitButton {
  background-color: #f8d00d;
  border: none;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
  font: 700 22px/40px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 40px;
  padding: 13px 70px;
  width: 100%;
}

.contactTitle{
  text-align: center;
  font: 700 32px/1.2 Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 50px;
  margin-top: 60px;

}

@media (max-width: 991px) {
  .contactForm {
    margin: 40px auto;
    padding: 0 20px;
  }

  .formRow {
    flex-direction: column;
    gap: 0;
  }
}