

.officeAssistant {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-family: Roboto, sans-serif;
    margin-bottom: 5%;
}

@media (max-width: 991px) {
    .officeAssistant {
        padding-top: 100px;
    }
}

.container {
    align-self: end;
    display: flex;
    width: 100%;
    flex-direction: column;
}

@media (max-width: 991px) {
    .container {
        max-width: 100%;
    }
}

.content {
    gap: 20px;
    display: flex;
    max-width: 1600px;
    margin: auto;
    background-color: white;
}

@media (max-width: 991px) {
    .content {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
    }
}

.textColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 45%;
    margin-left: 0;
    padding-left: 124px;
    padding-bottom: 20px;
    text-align: left;
}

@media (max-width: 991px) {
    .textColumn {
        width: 100%;
    }
}

.textBlock {
    display: flex;
    margin-top: 112px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .textBlock {
        max-width: 100%;
        margin-top: 40px;
    }
}

.text {
    display: flex;
    max-width: 100%;
    width: 550px;
    flex-direction: column;
    align-items: flex-start;
    color: #000;
    justify-content: start;
}

.title {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
}

@media (max-width: 991px) {
    .title {
        max-width: 100%;
        font-size: 40px;
    }
}

.description {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 15px;
    color: black;
}

@media (max-width: 991px) {
    .description {
        max-width: 100%;
    }
}

.ctaButton {
    align-self: start;
    background-color: #f8d00d;
    margin-top: 49px;
    min-height: 50px;
    gap: 10px;
    font-size: 16px;
    color: #000;
    border: 0px;
    border-radius: 30px;
    font-weight: 400;
    padding: 16px 80px;
}

@media (max-width: 991px) {
    .ctaButton {
        margin-top: 40px;
        padding: 0 20px;
    }

    .features{
        padding: 32px;
    }

}

.imageColumn {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 55%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .imageColumn {
        width: 100%;
    }
}

.heroImage {
    aspect-ratio: 1.49;
    object-fit: contain;
    object-position: right;
    width: 100%;
    flex-grow: 1;
}

@media (max-width: 991px) {
    .heroImage {
        max-width: 100%;
        margin-top: 40px;
    }
}

.mainContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

@media (max-width: 991px) {
    .mainContent {
        max-width: 100%;
        margin-top: 40px;
    }
}

.features {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;

}

@media (max-width: 991px) {
    .features {
        max-width: 100%;
    }
}

.featuresTitle {
    color: #172755;
    font-size: 40px;
    font-weight: 700;
}

.featuresDescription {
    color: #8794ba;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 53px;
}

@media (max-width: 991px) {
    .featuresDescription {
        max-width: 100%;
        margin-top: 40px;
    }
}

.featureIcons {
    align-self: stretch;
    display: flex;
    margin-top: 63px;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    justify-content: start;
    max-width: 1600px;
    margin: auto;
    padding: 20px;
}

@media (max-width: 991px) {
    .featureIcons {
        margin-top: 40px;
    }
}

.featureIcon {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    align-items: flex-start;
    margin: auto 0;
    padding: 0 8px;
}

.iconImage {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 40px;
}

.iconTitle {
    color: #172755;
    font-weight: 700;
    margin-top: 26px;
    font-size: 24px;
}

.iconDescription {
    color: #8794ba;
    font-weight: 400;
    line-height: 22px;
    align-self: stretch;
    margin-top: 15px;
    text-align: left;
}

.screenshots {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 991px) {
    .screenshots {
        max-width: 100%;
        margin-top: 40px;
        padding: 0px 32px;
    }
}

.screenshotWrapper {
    display: flex;
    align-items: center;
    gap: 40px 100px;
    justify-content: start;
    flex-wrap: wrap;
    margin-top: 120px;
}

@media (max-width: 991px) {
    .screenshotWrapper {
        max-width: 100%;
        margin-top: 40px;
    }
}

.screenshotWrapper.reversed {
    flex-direction: row-reverse;
}

.screenshotText {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 480px;
    margin: auto 0;
}

@media (max-width: 991px) {
    .screenshotText {
        max-width: 100%;
    }
}

.screenshotTitle {
    color: #172755;
    font-size: 40px;
    font-weight: 700;
}

@media (max-width: 991px) {
    .screenshotTitle {
        max-width: 100%;
    }
}

.screenshotDescription {
    color: #8794ba;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-top: 30px;
}

@media (max-width: 991px) {
    .screenshotDescription {
        max-width: 100%;
    }
}

.screenshotImageWrapper {
    align-self: stretch;
    display: flex;
    min-width: 240px;
    flex-direction: column;
    width: 600px;
    margin: auto 0;
}

@media (max-width: 991px) {
    .screenshotImageWrapper {
        max-width: 100%;
    }
}

.screenshotImg {
    aspect-ratio: 1.46;
    object-position: center;
    width: 500px;
    border-radius: 30px;
}

@media (max-width: 991px) {
    .screenshotImg {
        max-width: 100%;
    }
}

.footerImage {
    aspect-ratio: 5.26;
    object-fit: contain;
    object-position: center;
    width: 100%;
    margin-top: 62px;
}

@media (max-width: 991px) {
    .footerImage {
        max-width: 100%;
        margin: 40px 7px 0 0;
    }
}

.footer {
    background-color: #e7e7e7;
    display: flex;
    height: 318px;
    align-items: start;
    gap: 40px 42px;
    justify-content: start;
    padding: 50px 120px 0 132px;
}

@media (max-width: 991px) {
    .footer {
        margin-right: 7px;
        padding: 0 20px;
    }
}

.footerContent {
    display: flex;
    min-width: 240px;
    width: 1176px;
    align-items: start;
    gap: 40px 142px;
    justify-content: start;
}

.footerInfo {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: start;
    width: 678px;
}

@media (max-width: 991px) {
    .footerInfo {
        max-width: 100%;
    }
}

.footerLogo {
    aspect-ratio: 3;
    object-fit: contain;
    object-position: center;
    width: 117px;
    max-width: 100%;
}

.footerCompanyName {
    color: #191825;
    margin-top: 23px;
    font: 700 23px/1.2 Roboto, -apple-system, Roboto, Helvetica, sans-serif;
}

.footerAddress {
    color: rgba(25, 24, 37, 0.75);
    margin-top: 23px;
    font: 400 18px/14px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
    .footerAddress {
        max-width: 100%;
    }
}

.footerContact {
    display: flex;
    min-height: 239px;
    flex-direction: column;
    justify-content: end;
}

.contactTitle {
    color: #191825;
    font: 700 23px/1.2 Roboto, -apple-system, Roboto, Helvetica, sans-serif;
}

.contactPhone {
    color: rgba(25, 24, 37, 0.75);
    margin-top: 10px;
    font: 400 18px/1.6 Inter, sans-serif;
}

.contactEmail {
    color: rgba(25, 24, 37, 0.75);
    margin-top: 10px;
    font: 400 18px/1.6 Inter, sans-serif;
}

.contactAddress {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    color: rgba(25, 24, 37, 0.75);
    justify-content: start;
    font: 400 18px/1.6 Inter, sans-serif;
}

.contactCity {
    margin-top: 8px;
}

.socialIcons {
    align-self: start;
    display: flex;
    margin-top: 10px;
    align-items: start;
    gap: 32px;
    justify-content: start;
}

.socialIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 32px;
}

.footerSpacer {
    display: flex;
    min-height: 310px;
}
/* Hero Section */
.hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    margin: auto;
    max-width: 1800px;
}

/* Default Style for Larger Screens */
.hero img {
    width: 45%; /* Smaller width to prevent overlap */
    max-width: 100%; /* Ensures it doesn’t exceed container */
}

.oaText {
    width: 45%; /* Matching image width */
    padding: 20px 40px; /* Adjust padding for larger screens */
    box-sizing: border-box;
}

.oaText button {
    padding: 10px 20px;
    border-radius: 30px;
    background: #f8d00d;
    border: none;
}

/* Adjustments for Medium Screens */
@media screen and (max-width: 1024px) {
    .hero {
        padding: 15px;
    }

    .oaText {
        padding: 15px 30px;
    }
}

/* Responsive Layout for Smaller Screens (740px and Below) */
@media screen and (max-width: 740px) {
    .hero {
        flex-direction: column-reverse;
        align-items: center;
        padding: 10px;
    }

    .hero img, .oaText {
        width: 100%;
        padding: 50px 32px; /* Add horizontal padding */
    }
}

/* Additional Style for List */
.list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1600px;
    margin: auto;
    padding: 0px 124px;
}

@media only screen and (max-width: 1000px) {
    .list{
        flex-direction: column;
    }
}

.featureIcon {
    text-align: left;
    margin: 10px 0;
}

.screenshotList > div:nth-child(odd) {
    background-color: #FAF8EF; /* Žuta pozadina za neparne redove */
    flex-direction: row-reverse;
    justify-content: center;
    padding: 50px;
}

.screenshotList > div:nth-child(even) {
    background-color: white; /* Bijela pozadina za parne redove */
        justify-content: center;
    flex-direction: row;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}