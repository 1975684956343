.heroSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 57px 165px 0;
  gap: 40px 100px;
  max-width: 1800px; /* Max width set to 1600px */
  margin: auto;
  font-family: 'Roboto', -apple-system, Roboto, Helvetica, sans-serif; /* Set font to Roboto */
}

.heroContent {
  display: flex;
  flex-direction: column;
  max-width: 540px;
}

.heroTitle {
  color: #000;
  font-weight: 700; /* Bold font */
  font-size: 50px; /* Title font size */
  line-height: 60px;
}

.heroDescription {
  color: rgba(25, 24, 37, 0.5);
  font-weight: 400; /* Regular font weight */
  font-size: 18px; /* Font size for description */
  line-height: 29px;
  margin-top: 43px;
}

.ctaButton {
  align-self: flex-start;
  background-color: #f8d00d;
  border: none;
  border-radius: 100px;
  color: #000;
  cursor: pointer;
  font-weight: 700; /* Bold font for button */
  font-size: 14px; /* Font size for button */
  line-height: 1.2;
  margin-top: 43px;
  padding: 16px 32px;
}

.heroImage {
  aspect-ratio: 1.03;
  max-width: 553px;
  object-fit: contain;
  width: 100%;
}

@media (max-width: 991px) {
  .heroSection {
    flex-direction: column;
    padding: 40px 20px 0;
  }

  .heroTitle {
    font-size: 40px; /* Smaller title font size on mobile */
    line-height: 53px;
  }

  .heroDescription,
  .ctaButton {
    margin-top: 20px; /* Reduced margin for mobile */
  }

  .heroImage {
    margin-top: 40px;
  }
}


/* Example CSS adjustments */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10; /* Lower z-index than the contactPopup */
}

.contactPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 20; /* Higher z-index than the overlay */
}

.closeButton {
    margin-top: 10px;
}
