.contactSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: whitesmoke;
  justify-content: center;
  align-items: center;
}

.contactContent {
  display: flex;
  flex-direction: column;
  padding: 50px 132px;
  max-width: 1600px;
  margin: 0 auto; /* Centers the content */
}

.textSection {
  flex: 1;
  padding-right: 20px;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 10px;
}

.subtitle {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 5px;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
}

.imageSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.map {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .contactContent {
    flex-direction: row;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .textSection {
    padding-right: 0;
    margin-bottom: 20px;
  }
}
