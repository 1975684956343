.landingPage {
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.heroSection {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 500px;
  width: 100%;
  padding-bottom: 213px;
  align-items: center;
  font: 700 16px/1.2 Roboto, sans-serif;
}

.heroBg {
  position: absolute;
  inset: 0;
  height: 600px;
  width: 100%;
  object-fit: cover;
}

.header {
  position: relative;
  background: #fff;
  width: 100%;
  display: flex;
  min-height: 89px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 165px;
  font: 14px/1.2 Roboto, sans-serif;
}

.logo {
  aspect-ratio: 3;
  object-fit: contain;
  width: 117px;
  align-self: stretch;
  margin: auto 0;
}

.nav {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 64px;
  color: rgba(25, 24, 37, 0.5);
}

.navLink {
  color: #222831;
  text-decoration: none;
}

.ctaButton {
  border-radius: 100px;
  background: #f8d00d;
  padding: 16px 32px;
  border: none;
  cursor: pointer;
}

.heroContent {
  position: relative;
  text-align: center;
  color: #fff;
  margin-top: 280px;
}

.heroTitle {
  font-size: 42px;
  text-shadow: 4px 2px 4px rgba(0, 0, 0, 0.25);
  margin: 0;
}

.heroSubtitle {
  font-size: 24px;
  font-weight: 400;
  margin: 8px 0;
}

.featureSection {
  background: #fff;
  padding: 116px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
      max-width: 1800px;
    margin: auto;
}

.featureTitle {
  color: #2d2424;
  font: 700 42px Roboto, sans-serif;
  text-align: center;
  margin: 0;
}

.featureDescription {
  color: black;
  font: 500 18px Roboto, sans-serif;
  text-align: center;
  margin: 47px 0 72px;
  max-width: 800px;
  line-height: 1.5;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 100%;
  max-width: 1098px;
}

.featureCard {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.featureIcon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 200px;
  border-radius: 12px;
}

.featureContent {
  flex: 1;
}

.featureCardTitle {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 12px;
}

.featureCardText {
  color: black;
  line-height: 1.5;
  margin: 0 0 12px;
}

.learnMoreLink {
  color: black;
  text-decoration: none;
}

.associationSection {
  background: #FAF8EF;
}

.associationContent {
  max-width: 1264px;
  padding: 100px 124px;
  margin: 0 auto;
  display: flex;
  gap: 40px;
  flex-direction: row-reverse;
  align-items: center;
}

.associationImage {
  width: 50%;
  object-fit: contain;
}

.associationText {
  flex: 1;
}

.associationTitle {
  font: 700 42px Roboto, sans-serif;
  margin: 0 0 37px;
}

.associationDescription {
  font: 500 16px Roboto, sans-serif;
  line-height: 1.5;
  margin: 0;
}

.associationLink {
  text-decoration: underline;
  display: block;
  margin-top: 20px;
}

.audioContent {
  display: flex;
  flex-direction: row-reverse;
  gap: 80px;
  max-width: 1264px;
  margin: 0 auto;
  align-items: center;
  padding: 100px 124px;
  justify-content: space-between;
}

.audioInfo {
  flex: 0 0 40%;
}

.audioTitle {
  color: black;
  font: 700 42px Roboto, sans-serif;
  margin: 0;
}

.audioDescription {
  color: black;
  font: 400 16px Roboto, sans-serif;
  line-height: 1.5;
  margin: 40px 0;
  width: 100%;
}

.audioButton {
  border-radius: 40px;
  background: #ffcc00;
  border: none;
  padding: 18px 32px;
  cursor: pointer;
    font: 500 16px Roboto, sans-serif;
}

.audioDemo {
width: 50%;
  height: 300px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.audioDemoImage {
  width: 100%;
  aspect-ratio: 2.94;
  object-fit: contain;
}

.audioDemoText {
  color: black;
  font: 400 16px Roboto, sans-serif;
  line-height: 1.5;
  padding: 40px;
  margin: 0;
}

.footer {
  background: #e7e7e7;
  padding: 62px 120px;
}

.footerContent {
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  gap: 142px;
}

.companyInfo {
  flex: 1;
}

.footerLogo {
  width: 117px;
  height: auto;
}

.companyName {
  color: #191825;
  font: 700 23px/1.2 Roboto, sans-serif;
  margin: 23px 0;
}

.companyDetails {
  color: rgba(25, 24, 37, 0.75);
  font: 400 18px/29px Roboto, sans-serif;
  margin: 0;
}

.contactInfo {
  flex: 0 0 auto;
}

.contactTitle {
  color: #191825;
  font: 700 23px/1.2 Roboto, sans-serif;
  margin: 0 0 10px;
}

.contactPhone,
.contactEmail {
  color: rgba(25, 24, 37, 0.75);
  font: 400 18px/1.6 Roboto, sans-serif;
  margin: 10px 0;
}

.contactAddress {
  color: rgba(25, 24, 37, 0.75);
  font: 400 18px/1.6 Roboto, sans-serif;
  font-style: normal;
  margin: 10px 0;
}

.socialLinks {
  display: flex;
  gap: 32px;
  margin-top: 32px;
}

.socialIcon {
  width: 32px;
  height: 32px;
}

@media (max-width: 1200px) {
  .header {
    padding: 20px 80px;
  }

  .footerContent {
    gap: 80px;
  }
}

@media (max-width: 991px) {
  .heroSection {
    min-height: 700px;
    padding-bottom: 200px;
  }

  .header {
    padding: 20px 40px;
    flex-wrap: wrap;
    gap: 20px;
  }

  .nav {
    order: 3;
    width: 100%;
    justify-content: center;
  }

  .heroTitle {
    font-size: 48px;
  }

  .featureSection {
    padding: 80px 40px;
  }

  .featureGrid {
    grid-template-columns: 1fr;
  }

  .associationContent {
    flex-direction: column;
  }

  .associationImage {
    width: 100%;
  }

  .audioContent {
    flex-direction: column;
  }

  .audioInfo {
    flex: none;
  }

  .footer {
    padding: 40px 20px;
  }

  .footerContent {
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .nav {
    gap: 32px;
  }

  .featureCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .featureIcon {
    width: 160px;
  }
}

@media (max-width: 480px) {
  .heroTitle {
    font-size: 36px;
  }

  .heroSubtitle {
    font-size: 18px;
  }

  .featureTitle {
    font-size: 36px;
  }

  .featureDescription {
    font-size: 18px;
  }

  .audioTitle {
    font-size: 36px;
  }

  .audioDescription {
    font-size: 18px;
    margin: 40px 0;
  }

  .audioDemoText {
    font-size: 18px;
    padding: 20px;
  }
}

.navLink:hover,
.navLink:focus {
  color: #f8d00d;
}

.ctaButton:hover,
.ctaButton:focus {
  background: #e6c00c;
}

.learnMoreLink:hover,
.learnMoreLink:focus {
  color: #a34d2b;
  text-decoration: underline;
}

.audioButton:hover,
.audioButton:focus {
  background: #e6b800;
}

.socialLinks a:hover,
.socialLinks a:focus {
  opacity: 0.8;
}

:focus {
  outline: 2px solid #f8d00d;
  outline-offset: 2px;
}

:focus:not(:focus-visible) {
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
