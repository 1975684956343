/* serviceCard.css */

/* Style for the service cards grid */
.serviceCards {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns by default */
    gap: 20px; /* Space between the cards */
    max-width: 900px; /* Optional: set a max-width */
    margin: 0 auto; /* Center the section horizontally */
}

/* Style for each card */
.serviceCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.35) 100%);
    padding: 5px 10px;
    border-radius: 10px;
}

/* Style for the image in each card */
.serviceCard img {
    width: 48px;
    height: 48px;
}

/* Content within the card */
.cardContent {
    margin-left: 20px;
}

.cardContent h3 {
    font-size: 16px;
    font-weight: 700;
}

.cardContent p {
    color: black;
    font-size: 16px;
}

