/* Održavanje postojećeg stylinga, dodana izmjena za font, veličinu teksta i responzivnost */

.aboutSection {
  display: flex;
  align-items: center;
  gap: 40px 159px;
  padding: 165px 165px 129px 0px;
  max-width: 1600px;
  margin: auto;
  font-family: 'Roboto', -apple-system, Roboto, Helvetica, sans-serif; /* Roboto font */
}

.aboutImage {
  aspect-ratio: 1.14;
  border-radius: 0 500px 500px 0;
  object-fit: contain;
  width: 100%;
  max-width: 635px;
}

.aboutContent {
  display: flex;
  flex-direction: column;
  max-width: 535px;
}

.aboutTitle {
  color: #333;
  font-weight: 700; /* Naslov 700 weight */
  font-size: 24px; /* Naslov 24px */
  letter-spacing: 4.6px;
  text-transform: uppercase;
  margin-bottom: 10px; /* Margin za bolji razmak od podnaslova */
}

.aboutSubtitle {
  color: #191825;
  font-weight: 700; /* Naslov 700 weight */
  font-size: 24px; /* Naslov 24px */
  line-height: 30px;
  margin-top: 16px;
}

.aboutDescription {
  color: rgba(25, 24, 37, 0.5);
  font-weight: 500; /* Ostatak teksta 500 weight */
  font-size: 18px; /* Veličina teksta 18px */
  line-height: 29px;
  margin-top: 32px;
}

.statsContainer {
  display: flex;
  gap: 32px;
  margin-top: 64px;
}

.statItem {
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(25, 24, 37, 0.1);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  flex: 1;
}

.statNumber {
  color: #191825;
  font-weight: 700; /* Naslovni brojevi 700 weight */
  font-size: 35px;
  line-height: 1.2;
}

.statLabel {
  color: #191825;
  font-weight: 500; /* Ostatak teksta 500 weight */
  font-size: 18px; /* Veličina teksta 18px */
  margin-top: 16px;
  text-align: center;
}

@media (max-width: 991px) {
  .aboutSection {
    flex-direction: column;
    padding: 60px 20px;
  }

  .aboutSubtitle {
    font-size: 24px;
    line-height: 30px;
  }

  .statsContainer {
    flex-direction: column;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .aboutTitle {
    font-size: 20px; /* Manji naslovi na manjim ekranima */
  }

  .aboutSubtitle {
    font-size: 22px;
  }

  .aboutDescription {
    font-size: 16px; /* Manji tekst na manjim ekranima */
  }

  .statsContainer {
    gap: 20px;
  }

  .statItem {
    padding: 20px;
  }

  .statNumber {
    font-size: 28px;
  }

  .statLabel {
    font-size: 16px;
  }
}
