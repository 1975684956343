.header{
    margin: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    padding: 50px 124px;
    background: #FAF8EF;
}

.headerContentWrapper{
    max-width: 1600px;
    margin: auto;
     display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header h2{
    font-size: 32px;
}

.header h3{
    font-size: 24px;
}

.postsWrapper{
    max-width: 1600px;
    margin: auto;
}

.postImage{
    max-width: 1600px;
    margin: auto;
}
/* Articles.module.css */

/* Global container style to limit max-width and center content */
.container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    max-width: 1600px;
    margin: 0 auto; /* Center the content horizontally */
    padding-left: 124px; /* Apply padding on left */
    padding-right: 124px; /* Apply padding on right */
}

.container {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 0 auto;
    max-width: 1600px;
    padding: 100px 124px;
    box-sizing: border-box;
}

/* Each card style */
.card {
    min-width: 275px;
    flex: 1 1 300px; /* Allow card to grow, shrink, and take at least 300px */
    max-width: 400px; /* You can adjust this to match the desired max card width */
    display: flex;
    flex-direction: column;
}

/* To make the cards have the same height */
.card > .MuiCardContent-root {
    flex-grow: 1; /* Ensure that the card content takes up available space */
}

/* Ensure that cards do not stretch and maintain consistent height */
@media (max-width: 768px) {
    .container {
        padding-left: 10px; /* Less padding on small screens */
        padding-right: 10px;
    }
    .card {
        width: 100%; /* Cards take full width on mobile */
    }

    .headerContentWrapper{
        display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FAF8EF;
}

    .header{
        padding: 50px 32px;
    }

    .headerContentWrapper img{
        width: 300px;
    }

    .container {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 0 auto;
    max-width: 1600px;
    padding: 50px 32px;
    box-sizing: border-box;
}


}


.postImage{
    max-width: 1600px;
    margin: auto;
    padding: 100px 124px;
}

/* Stilizacija za <pre> i <code> */

pre {
    background-color: #f8f9fa; /* Lagana siva pozadina */
    border: 1px solid #e1e4e8; /* Blaga siva granica */
    border-radius: 6px; /* Zaobljeni rubovi */
    padding: 16px; /* Prostor unutar okvira */
    overflow-x: auto; /* Omogućava horizontalno scrollanje ako je kod predug */
    font-family: 'Courier New', Courier, monospace; /* Monospace font za kod */
    font-size: 14px; /* Veličina teksta */
    line-height: 1.5; /* Povećava razmak između linija */
    color: #333; /* Tamna boja teksta */
    margin-bottom: 20px; /* Razmak između blokova koda i ostatka sadržaja */
}

code {
    font-family: 'Courier New', Courier, monospace; /* Monospace font */
    font-size: 14px; /* Veličina teksta */
    background-color: #f8f9fa; /* Lagana siva pozadina */
    color: #d63384; /* Naglasak za inline kod */
    padding: 2px 4px; /* Blagi padding */
    border-radius: 4px; /* Zaobljeni rubovi */
}

.card {
    height: 100%; /* Osigurava da kartice imaju punu visinu unutar mreže */
}


.card {
    flex: 1 1 calc(33.33% - 20px); /* Kartice će zauzimati jednak prostor */
    height: 130px; /* Omogućava punu visinu */
    display: flex;
    flex-direction: column; /* Sadržaj ide od vrha prema dnu */
    justify-content: space-between; /* Razmak između sadržaja i gumba */
    box-sizing: border-box;
}

.cardContent {
    flex-grow: 1; /* Sadržaj kartice zauzima sav raspoloživi prostor */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Poravnaj sadržaj na vrh */
}

.cardActions {
    margin-top: auto; /* Gumb će uvijek biti pritisnut na dno */
    text-align: center; /* Opcionalno, centriranje gumba */
}
/* Definiraj osnovne stilove za lijevu i desnu stranu */
.leftContainer {
  padding: 20px;
  border-right: 1px solid #ccc;
  height: 80vh;
  overflow-y: auto;
}

.rightContainer {
  flex: 2;
  padding: 20px;
  height: 80vh;
  overflow-y: auto;
}

   .mainContainer{
        flex-direction: row;
    }

/* Dodaj media query za mobilni prikaz */
@media (max-width: 768px) {

    .mainContainer{
        flex-direction: column;
    }

  /* Lijeva strana - kartice */
  .leftContainer {
    display: flex;
    flex-direction: row; /* Postavi kartice horizontalno */
    gap: 10px; /* Razmak između kartica */
    overflow-x: auto; /* Omogućuje horizontalni scroll */
    height: auto; /* Makni visinu za mobilne uređaje */
    padding: 10px;
    border-right: none; /* Makni desnu granicu */
    border-bottom: 1px solid #ccc; /* Dodaj donju granicu */
  }

  /* Desna strana - članak */
  .rightContainer {
    display: block; /* Na mobilnim uređajima članak ispod kartica */
    height: auto; /* Makni visinu za mobilne uređaje */
    padding: 10px; /* Dodaj padding */
    border-top: 1px solid #ccc; /* Dodaj gornju granicu */
    margin-top: 20px; /* Razmak između kartica i članka */
  }

  /* Dodatni stil za kartice na mobilnom prikazu */
  .card {
    flex-shrink: 0; /* Ne dopušta karticama da se smanjuju */
  }
}
