.homePage {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.copyright {
  color: rgba(25, 24, 37, 0.75);
  font: 400 18px/1.6 Inter, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 991px) {
  .homePage {
    padding-bottom: 20px;
  }
}
.homePage {
    position: relative;
}

.floatingButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #FFD700;
    color: black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.contactPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    z-index: 11;
    text-align: center;
}

.contactPopup h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.contactPopup p {
    margin: 8px 0;
    font-size: 14px;
}

.contactPopup a {
    color: #333;
    text-decoration: none;
}

.closeButton {
    margin-top: 15px;
    padding: 8px 12px;
    background-color: #FFD700;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}
