/* Održavanje postojećeg stylinga, s izmjenama za font, veličinu teksta i responzivnost */

.featureCard {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 25px;
  overflow: hidden;
  padding: 41px 14px;
  width: 300px;
  font-family: 'Roboto', -apple-system, Roboto, Helvetica, sans-serif; /* Roboto font */
}

.featureIcon {
  background-color: #f8d00d;
  border-radius: 50%;
  height: 39px;
  width: 39px;
}

.featureTitle {
  color: #191825;
  font-weight: 700; /* Naslov 700 weight */
  font-size: 18px; /* Naslov 24px */
}

.featureDescription {
  color: #191825;
  font-weight: 500; /* Ostatak teksta 500 weight */
  font-size: 18px; /* Veličina teksta 18px */
  margin-top: 10px;
}

@media (max-width: 991px) {
  .featureCard {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .featureTitle {
    font-size: 20px; /* Manji naslovi na manjim ekranima */
  }

  .featureDescription {
    font-size: 16px; /* Manji tekst na manjim ekranima */
  }

  .featureCard {
    width: 100%; /* Širina kartice na manjim ekranima */
  }
}
