.featuresSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 165px;
  max-width: 1800px; /* Maximum width set to 1600px */
  margin: auto;
  font-family: 'Roboto', -apple-system, Roboto, Helvetica, sans-serif; /* Set font to Roboto */
}

.sectionTitle {
  color: #828282;
  font-weight: 700; /* Title with bold font */
  font-size: 18px; /* Font size for title */
  line-height: 1.2;
  letter-spacing: 4.6px;
  text-transform: uppercase;
}

.sectionSubtitle {
  color: #191825;
  font-weight: 700; /* Subtitle with bold font */
  font-size: 24px; /* Font size for subtitle */
  line-height: 1.2;
  margin-top: 16px;
  text-align: center;
}

.featureCards {
  display: flex;
  flex-wrap: wrap;
  gap: 42px;
  justify-content: center;
  margin-top: 53px;
  width: 100%;
}

@media (max-width: 991px) {
  .featuresSection {
    padding: 40px 20px;
  }

  .sectionSubtitle {
    font-size: 20px; /* Adjusted for mobile */
  }

  .featureCards {
    margin-top: 40px;
  }
}
