/* ServicesSection.module.css */

.servicesSection {
  padding: 2rem;
  text-align: left; /* Poravnanje teksta ulijevo */
  font-family: 'Roboto', -apple-system, Roboto, Helvetica, sans-serif;
  background-color: white; /* Pozadina u žutom tonu */
  border-radius: 30px;
  max-width: 1800px;
  margin: 5% auto; /* Centriranje sekcije */
}

.sectionTitle {
  font-size: 24px; /* Velicina fonta */
  font-weight: 700; /* Font weight */
  text-align: center; /* Poravnanje naslova ulijevo */
}

.sectionSubtitle {
  font-size: 18px; /* Velicina fonta */
  font-weight: 500; /* Font weight */
  margin-bottom: 2rem;
  text-align: center; /* Poravnanje podnaslova ulijevo */
}

.columnsContainer {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Razmak između stupaca */
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Razmak između kartica unutar stupca */
  width: 550px;
}

/* Poravnavanje teksta unutar svake kartice ulijevo */
.serviceCard {
  text-align: left;
  background: linear-gradient(to bottom, #ffffff, #f8d00d); /* Gradijent pozadina kartica */
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Lagani sjeni na karticama */
}


/* Media query za veće ekrane (tablet i ispod) */
@media (max-width: 991px) {
  .servicesSection {
    padding: 40px 20px;
  }

  .sectionSubtitle {
    font-size: 32px;
  }

  .serviceCards {
    margin-top: 40px;
  }

  /* Kolone postaju u jednom stupcu na manjim ekranima */
  .columnsContainer {
    flex-direction: column;
    align-items: center;
  }

  /* Kartice se prilagođavaju na ekranima manjih uređaja */
  .column {
    flex: 1 1 100%; /* Kartice zauzimaju puni prostor u jednom stupcu */
    margin-bottom: 20px; /* Razmak između kartica */
  }
}

/* Media query for mobile devices (max-width: 768px) */
@media (max-width: 768px) {
  .servicesSection {
    padding: 30px 15px; /* Manje paddinga za manje ekrane */
  }

  .sectionTitle {
    font-size: 14px; /* Manji font za naslove */
  }

  .sectionSubtitle {
    font-size: 14px; /* Manji font za podnaslove */
  }

  /* Zadržavanje jednog stupca za kartice na mobitelima */
  .columnsContainer {
    flex-direction: column;
    gap: 15px; /* Manji razmak između kartica */
  }

  .serviceCard {
    padding: 1.5rem; /* Veće paddinga za bolji razmak na mobitelima */
  }

  .cardContent {
    margin-left: 0; /* Reset left margin za mobilne uređaje */
  }

  .column{
    width: 100%;
  }

}