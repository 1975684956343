.footer {
  background: whitesmoke;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  font: 700 16px Roboto, sans-serif;
  overflow: hidden;
}

.footerContent {
  display: flex;
  width: 100%;
  max-width: 1232px;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  color: black;
  white-space: nowrap;
}

.logoIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 70px;
  fill: var(--Blue-Blue-70, #3a86ff);
}

.logoText {
  font-variant-numeric: lining-nums proportional-nums;
  flex-grow: 1;
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  margin-top: 37px;
  width: 100%;
  max-width: 363px;
  gap: 10px;
  font-weight: 400;
  justify-content: center;
}

.socialLinks {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
  justify-content: center;
  max-width: 100%;
}

.socialIcon {
  background-color: var(--Blue-Blue-70, #3a86ff);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  fill: var(--Blue-Blue-70, #3a86ff);
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;
  stroke-width: 1px;
  stroke: var(--Blue-Blue-50, #76aeff);
  align-self: stretch;
  margin-top: 20px;
}

.copyright {
  color: var(--White-White-90, #cfd3d7);
  text-align: center;
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Media Queries */
@media (max-width: 991px) {
  .footer {
    padding: 20px;
  }

  .footerContent {
    max-width: 100%;
    align-items: center;
  }

  .logo {
    white-space: normal;
  }

  .navigation {
    justify-content: center;
    gap: 15px;
  }

  .socialLinks {
    gap: 15px;
    white-space: normal;
  }

  .divider {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .logoIcon {
    width: 50px;
  }

  .navigation {
    gap: 10px;
  }

  .socialLinks {
    gap: 10px;
  }

  .divider {
    margin-top: 20px;
  }

  .copyright {
    font-size: 12px;
    margin-top: 15px;
  }
}
